import React, {useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { navigate } from "@reach/router"
import ThemeContext from "../utils/theme"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import { Container, Image, Nav, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import firebase from "../components/firebase"
import { fireAnalytics } from "../components/firebase"

import { loggedUser, printing } from  "../components/FirebaseAuth"
import { LoadStateHouse, LoadRandomHouse } from  "../components/LoadStateHouse"
import { UserData }  from  "../components/UserData"
import './grid.css';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import DailyPart from "../components/DailyPart"

/*
console.log("local storage starting ",localStorage)

localStorage.setItem('userData', JSON.stringify({'filler':3,'test':2,'ligma':'balls'}) )

console.log("local storage userdata is ",JSON.parse(localStorage.getItem('userData')) )

localStorage.removeItem("userData");

console.log("local storage starting ",localStorage)

*/


export default ({ data }) => {

  let currentUser = loggedUser()
  //console.log("current user is",currentUser)
  //let userData = {}

  const [userData, setUserData] = useState(null)
  //let userData = {}
  
  const [cats, setCats] = useState([])

  const { dark, setModeDark, toggleDark, firstTime, setFirst } = useContext(ThemeContext)

  useEffect(() => 
  {
    if (currentUser && (currentUser.email != 0) )
    {
      //userData = UserData()
      async function uData()
      {

        //console.log("RUNNING USERS AGAIN FIREBASE CALL")
        await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
        .then((doc) => {
          if (doc.exists) 
          {
              //console.log("Document data:", doc.data());
              //doc.data()
              setUserData(doc.data())
              localStorage.setItem('userData', JSON.stringify(doc.data()) )
              //userData = doc.data()

          } 
          else 
          {
              // doc.data() will be undefined in this case
              console.log("No such user!");
          }
        }).catch((error) => {
            console.log("Error getting user:", error);
        });
      }
      let userStorage = JSON.parse(localStorage.getItem('userData'))
      if (userStorage == null)
      {
        
        uData()
      }
      else 
      {
        setUserData(userStorage)
        //console.log("didnt run firebase call because data is in local storage", userStorage)
      }
    }
    //toggleDark()
    /*let userDarkMode = JSON.parse(localStorage.getItem('userDarkMode'))
    if(userDarkMode)
    {
      console.log("set dark mode in main page")
      setModeDark()
    }*/
    /*
    if (firstTime)
    {
      setTimeout(() => 
      {  
        toggleDark()
      }, 120);
      setTimeout(() => 
      {  
        toggleDark()
      }, 77);
      setFirst()
    }*/
    fireAnalytics.logEvent('Visted home page')
    //console.log("logged event for visiting home page")

    //console.log("RUNNING CATEGORIES FIREBASE CALL")
    firebase.firestore().collection('Categories').get()
    .then((querySnapshot) => 
    {
      let tempCats = []
      querySnapshot.docs.map((doc) => 
      {
        if (doc.exists) 
        {
          //setCats(doc.data())
          //console.log("categories",doc.id)
          let tempObj = doc.data()
          tempObj["idHash"] = doc.id
          tempCats.push(tempObj)
        } 
        else 
        {
          console.log("No categories!");
        }
      })
      setCats(tempCats)
      //console.log("categories",tempCats)

    }).catch((error) => 
    {
      console.log("Error getting categories:", error);
    });

  }, [currentUser])
    


  function shuffle(array) 
  {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const [finished, setFinished] = useState("")
  
  const LoadWithState = async (evt) => 
  {
    let retLoad = await LoadStateHouse(evt,userData)
    console.log("asdads",retLoad)
    if(retLoad == false)
    {
      console.log("All houses in this state have been played")
      setFinished("Completed All Houses In This State")
    }
    else
    {
      setFinished("")
    }
  }
  const LoadWithRandom = async (evt) => 
  {
    evt.preventDefault();
    LoadRandomHouse(userData)
  }
  const LoadDailyHome = async (evt) => 
  {
    evt.preventDefault();
    navigate(`/Daily`)
  }

  const LoadNewCompare = async (evt) => 
  {
    let mode = 'return'
    let [randomHome1,randomHome2] = await LoadRandomHouse(userData,mode)

    //console.log("the two new homes are",randomHome1,randomHome2)
    navigate(`/compare?home1=${randomHome1}&home2=${randomHome2}`)

    
  }

  
  

  let carouselItems = [
    {key: "M6594039110_MD", pic:"https://ap.rdcpix.com/5dc138f032752cbae69cb1fcefe5afcfl-m1954928286od-w1024_h768_x2.webp"},
    {key: "M9443339587_AR", pic:"https://ap.rdcpix.com/030d6621ff9d88028d33d10fa03e90fbl-m2729099049od-w1024_h768_x2.webp"},
    {key: "M2429798166_AZ", pic:"https://ap.rdcpix.com/c2ba8905be7555d5517ca8cb2b19897fl-m440963967od-w1024_h768_x2.webp"},
    {key: "M2962802303_CA", pic:"https://ap.rdcpix.com/05ddb13d1a5c4caf08478544eca37a9fl-m1836488897od-w1024_h768_x2.webp"},
    {key: "M6243265216_VA", pic:"https://ap.rdcpix.com/d36c33fde545e850d443c9763748af12l-m790958679od-w1024_h768_x2.webp"},
    {key: "M1566290820_NV", pic:"https://ap.rdcpix.com/0babd23fca5a440bf718bcffbaf2cfefl-m2056316412od-w1024_h768_x2.webp"},
    {key: "M8205952869_MO", pic:"https://ap.rdcpix.com/be077f7d3f237392214aef19b2021f98l-m1745291971od-w1024_h768_x2.webp"},
    {key: "M7223227801_IL", pic:"https://ap.rdcpix.com/8f9e55e8d67834f5a7caf5273b3cc384l-m933649921od-w1024_h768_x2.webp"},
    {key: "M6444862166_FL", pic:"https://ap.rdcpix.com/d79cdfda73e1ebba649e5b59713fa696l-m404715354od-w1024_h768_x2.webp"}
  ]


  function LoadCarousel (props)
  {
    return (
      <Carousel style={{ maxWidth:"700px", margin:"auto", background:"transparent"}} hasMediaButton={false} hasIndexBoard={false} hasSizeButton={false}>
      { 
        carouselItems.map((f,index) => 
        {
          let played = false
          let points = 0
          let pics = f.pic

          let mlsParam, stateParam
          let params = String(f.key)
          if (params.length > 0)
          {
            let divider = params.search("_")
            mlsParam = params.substring(0,divider)
            stateParam = params.substring((divider+1))
          }
          if(currentUser && userData)
          {
            let tempUser = userData
            Object.entries(tempUser).map((t,k) =>
            {
              if(t[0] == f.key)
              {
                played = true
                points = t[1][0]
              }
            })
          }    
          //console.log("list entry",f)
          let imgWidth = "95%"
          if(played)
          {
            return(
              <>
              <div key={`topList-${index}`} className="playedHouses">
                <div className="imgContainer indexCarousel">
                  <a href={`/play?state=${stateParam}&mls=${mlsParam}` } style={{color:"#00DD00"}}>
                    <img src={pics} alt="house" width={imgWidth} className="indexCarousel imgGray" style={{border:"5px solid #00DD00", backgroundSize: "cover", borderRadius: "10px",margin:"5px"}}></img> 
                    <div className="imgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
                      Scored {points}
                    </div>
                  </a>
                </div>
              </div>
              </>
            )
          }
          else 
          {
            
            if (mlsParam!="" && stateParam!="")
            {
              return(
                <>
                <div key={`topList-${index}`} style={{listStyleType:"circle"}} className="imgContainer indexCarousel">
                  <a href={`/play?state=${stateParam}&mls=${mlsParam}`}>
                    <img src={pics} alt="house" className="indexCarousel"  width={imgWidth} style={{border:"5px solid #007bff", backgroundSize: "cover", borderRadius: "10px",margin:"5px"}} ></img> 
                  </a>
                </div>
                </>
              )
            }
          }

        })
      
      }
      </Carousel>
    )
  }

  function SampleNextArrow(props) 
  {
    const { className, style, onClick } = props;
    return (
      <div class="fa-solid fa-circle-arrow-right slickEmulateRight"
      style={{ }}
      onClick={onClick}>

      </div>
    );
  }
  
  function SamplePrevArrow(props) 
  {
    const { className, style, onClick } = props;
    return (
      
      <div class="fa-solid fa-circle-arrow-left slickEmulateLeft"
      style={{ }}
      onClick={onClick}>

      </div>
    );
  }

  function SlickCarousel ()
  {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll:3,
      autoplay: false,
      autoplaySpeed: 5000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }
    return (
      <div style={{maxWidth:'90%', margin:'auto'}}>
        <Slider {...settings}>
          { 
            carouselItems.map((f,index) => 
            {
              let played = false
              let points = 0
              let pics = f.pic

              let mlsParam, stateParam
              let params = String(f.key)
              if (params.length > 0)
              {
                let divider = params.search("_")
                mlsParam = params.substring(0,divider)
                stateParam = params.substring((divider+1))
              }
              if(currentUser && userData)
              {
                let tempUser = userData
                Object.entries(tempUser).map((t,k) =>
                {
                  if(t[0] == f.key)
                  {
                    played = true
                    points = t[1][0]
                  }
                })
              }    
              //console.log("list entry",f)
              let imgWidth = "95%"
              let imgHeight= "90%"
              if(played)
              {
                return(
                  <>
                  <div key={`topList-${index}`} className="playedHouses" style={{margin:"auto", background:"transparent"}}>
                    <div className="slickContainer">
                      <a href={`/play?state=${stateParam}&mls=${mlsParam}` } style={{color:"#00DD00"}}>
                        <img src={pics} alt="house" className="slickItem slickImgGray" style={{border:"3px solid #00DD00", backgroundSize: "cover", borderRadius: "10px",margin:"2px"}}/>
                        <div style={{position:'relative'}} >
                          <div className="slickImgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
                            Scored {points}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  </>
                )
              }
              else 
              {
                
                if (mlsParam!="" && stateParam!="")
                {
                  return(
                    <>
                    <div key={`topList-${index}`} style={{listStyleType:"circle"}} className="slickContainer" >
                      <a href={`/play?state=${stateParam}&mls=${mlsParam}`}>
                        <img src={pics} alt="house" className="slickItem" style={{border:"0px solid #292c2f", backgroundSize: "cover", borderRadius: "1rem",margin:"2px"}} ></img> 
                      </a>
                    </div>
                    </>
                  )
                }
              }

            })
          
          }
        </Slider>
      </div>
    );

  }

  /*
  const [CarouselRefresh, setCarouselRefresh] = useState()
  useEffect(() => 
  {
    let temp = LoadCarousel()
    console.log("carousel is",temp,typeof(temp))
    setCarouselRefresh(temp)
  }, [userData])

*/

  //backgroundColor: "#00ffd5"

  
  
  return (
    <PageLayout>
      <SEO title="Home" />
        
        {/*<h2>Test your home valuation skills!</h2>*/}
        {/*}
        <br/><br/>
        <div className="text-color" style={{fontSize:"1.5rem"}}> Play The <a href={`/Daily`} className="linkColor" style={{color:'#00cc00'}}>Daily</a> Home!</div>
        */}
        <h1>The Home Price Guessing Game!</h1>
        {/*<>
          <DailyPart/>
          <br/>
          <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
          <br/>
        </>*/}
        <h3> Play a Featured Home:</h3>

        <SlickCarousel/>
        {
        //<h2>Click to play one of the homes below</h2>
        
        // <div className="text-left thicc play-grid scrolling"  style={{ maxWidth:"1000px", margin:"auto"}}>
        //<LoadCarousel/>
        
        // {
        //   carouselItems.map((f,index) => 
        //   {
        //     let played = false
        //     let points = 0
        //     let pics = f.pic

        //     let mlsParam, stateParam
        //     let params = String(f.key)
        //     if (params.length > 0)
        //     {
        //       let divider = params.search("_")
        //       mlsParam = params.substring(0,divider)
        //       stateParam = params.substring((divider+1))
        //     }
        //     if(currentUser)
        //     {
        //       Object.entries(userData).map((t,k) =>
        //       {
        //         if(t[0] == f.key)
        //         {
        //           played = true
        //           points = t[1][0]
        //         }
        //       })
        //     }    
        //     //console.log("list entry",f)
        //     let imgWidth = "300px" 
        //     let imgHeight ="200px" 
        //     if(played)
        //     {
        //       return(
        //         <>
        //         <div className="playBox">
        //           <div key={`topList-${index}`} className="playedHouses">
        //             <div className="imgContainer indexCarousel">
        //               <a href={`/play?state=${stateParam}&mls=${mlsParam}` } style={{color:"#00DD00"}}>
        //                 <img src={pics} alt="house" className="indexCarousel imgGray" style={{maxWidth:`${imgWidth}`, maxHeight:`${imgHeight}`,border:"5px solid #00DD00", backgroundSize: "cover", borderRadius: "10px",margin:"5px"}}></img> 
        //                 <div className="imgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
        //                   Scored ${points}
        //                 </div>
        //               </a>
        //             </div>
        //           </div>
        //         </div>
        //         </>
        //       )
        //     }
        //     else 
        //     {
              
        //       if (mlsParam!="" && stateParam!="")
        //       {
        //         return(
        //           <>
        //           <div className="playBox">
        //             <div key={`topList-${index}`} style={{listStyleType:"circle"}} className="imgContainer indexCarousel">
        //               <a href={`/play?state=${stateParam}&mls=${mlsParam}`}>
        //                 <img src={pics} alt="house" className="indexCarousel"  style={{maxWidth:`${imgWidth}`, maxHeight:`${imgHeight}`,border:"5px solid #007bff", backgroundSize: "cover", borderRadius: "10px",margin:"5px"}} ></img> 
        //               </a>
        //             </div>
        //           </div>
        //           </>
        //         )
        //       }
        //     }

        //   })

        // }

        //</div>
        }
        <br/>
        

        {
        // <div className="w-100 text-color thicc" style={{maxWidth: "90%", margin: "auto", textAlign:"left"}}>    
        //     <u>Simple Filters:</u><br/>
        // </div>
        // <br/><br/>
        }
        <br/>
        <h3> Game Modes:</h3>
        <div className="w-100" style={{ maxWidth: "80%", margin: "auto"}}>      
          <div className="thin indexTop-grid">

            {/*
              <div className="indexTopBoxes" 
              style={{fontWeight:"900",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px", padding:'0px',justifyContent:'start',background:`${dark ? '#292c2f' : '#e9ecef'}`}}>
                <img src="../../icons/UpDownDark.png" style={{objectFit: "cover", width:"100%", height:"180px"}}/>

                <table style={{height:"100px"}}>
                  <tbody>
                    <tr style={{ verticalAlign: "middle",zIndex:"4", color:"white" }}>
                      <td>
                        Higher or Lower  
                        <br/>
                        <Button onClick={LoadNewCompare} value="Random" style={{fontWeight:"900",textShadow: "#000 0px 0 5px,#000 0px 0 5px", 
                                                                                fontSize:"1.1rem", borderRadius:'20px', paddingLeft:'30px',paddingRight:'30px',letterSpacing:'2px'}}> 
                          Play 
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            */}
            <div className="indexTopBoxes" 
            style={{fontWeight:"900",textShadow: "#000 0px 0 5px,#000 0px 0 5px", padding:'0px',justifyContent:'start',background:`${dark ? '#292c2f' : '#e9ecef'}`}}>
              <img className="gameModePic" src="../../icons/Clocks.jpg" />
                  
              <table style={{height:"100px"}}>
                <tbody>
                  <tr style={{ verticalAlign: "middle",zIndex:"4", color:"white" }}>
                    <td> 
                      Today's Daily Home 
                      <br/>
                      <Button onClick={LoadDailyHome} value="Random" className="playButtons"> 
                        PLAY 
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div className="indexTopBoxes" 
            style={{fontWeight:"900",textShadow: "#000 0px 0 5px,#000 0px 0 5px", padding:'0px',justifyContent:'start',background:`${dark ? '#292c2f' : '#e9ecef'}`}}>
              <img src="../../icons/GreenDice.jpg" className="gameModePic"/>
                  
              <table style={{height:"100px"}}>
                <tbody>
                  <tr style={{ verticalAlign: "middle",zIndex:"4", color:"white" }}>
                    <td> 
                      Random Home  
                      <br/>
                      <Button onClick={LoadWithRandom} value="Random" className="playButtons"> 
                        PLAY 
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <form onSubmit={LoadWithState} >

              <div className="indexTopBoxes" 
              style={{fontWeight:"900",textShadow: "#000 0px 0 5px,#000 0px 0 5px", padding:'0px',justifyContent:'start',background:`${dark ? '#292c2f' : '#e9ecef'}`}}>
                <img src="../../icons/Map.png" className="gameModePic"/>

                <table style={{height:"100px",margin:'5px'}}>
                  <tbody>
                  <tr style={{ verticalAlign: "middle",zIndex:"4" }}>
                    <td>
                    
                      <label htmlFor="states" style={{color:"white", fontWeight:"900", textShadow: "#000 0px 0 5px,#000 0px 0 5px"}}>State:&nbsp;</label>
                      
                      <select name="states" id="states" style={{borderRadius:"8px",fontSize:"20px"}}>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>  

                      <br/>

                      <Button className="btn playButtons" type="submit" value="Play" > 
                        PLAY
                      </Button>
                      { finished && (() => 
                        {
                          return(
                            <OverlayTrigger 
                              key="top" 
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  <strong>{finished}</strong>.
                                </Tooltip>
                                
                              }>

                              <Button style={{ fontWeight:"600",borderWidth:"0px", textShadow: "#000 0px 0 5px,#000 0px 0 5px",background:"#dd3344"}}>!</Button>
                            
                            </OverlayTrigger>
                          )
                        })()
                      }
                      
                    </td>
                  </tr>
                  
                  </tbody>
                </table>
                
              </div>
            </form>

          </div>
            
        </div>

        <br/><br/>

        {/*
          <br/><br/><br/><br/>

          <div className="w-100 text-color thicc" style={{ maxWidth: "90%", margin: "auto", textAlign:"left"}}>    
          <u>Popular Categories:</u><br/><br/>
          </div>
          <div className="w-100 text-color" style={{ maxWidth: "90%", margin: "auto"}}>      
            <div className="boxes text-left features-grid thicc ">

              
              <div className="thin feat-grid">
                { cats &&
                  cats.map((e,ind) => 
                  {
                    return (
                      <>
                      <div key={`catBoxes-${ind}`} className="featBoxes" >
                        <div key={`catBoxesTitle-${ind}`} className="mid">{e.Name} </div>
                          { e.List &&
                            Object.entries(e.List).map((f,index) =>
                            {
                              //console.log("EEEEEE",e.idHash)
                              let played = false
                              let points = 0
                              let address = f[1]

                              let mlsParam, stateParam
                              let params = String(f[0])
                              if (params.length > 0)
                              {
                                let divider = params.search("_")
                                mlsParam = params.substring(0,divider)
                                stateParam = params.substring((divider+1))
                              }
                              if(currentUser && userData)
                              {
                                Object.entries(userData).map((t,k) =>
                                {
                                  if(t[0] == f[0])
                                  {
                                    played = true
                                    points = t[1][0]
                                  }
                                })
                              }    
                              //console.log("list entry",f)
                              let imgWidth = 220 
                              if(played)
                              {
                                return(
                                  <>
                                    <div key={`catList-${index}`} className="playedHouses imgContainer">
                                      <a href={`/play?state=${stateParam}&mls=${mlsParam}&listID=${e.idHash}` } style={{color:"#00DD00"}}>
                                        <img src={address} className="imgGray" alt="house" width={imgWidth} style={{border:"5px solid #00DD00",borderRadius: "10px",margin:"5px"}}></img> 
                                        <div className="imgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
                                          Scored {points}
                                        </div>
                                      </a>
                                    </div>
                                  </>
                                )
                              }
                              else 
                              {
                                
                                if (mlsParam!="" && stateParam!="")
                                {
                                  return(
                                    <>
                                      <div key={`catList-${index}`} style={{listStyleType:"circle"}} className="imgContainer">
                                        <a href={`/play?state=${stateParam}&mls=${mlsParam}&listID=${e.idHash}`}>
                                          <img src={address} alt="house" width={imgWidth} style={{border:"5px solid #007bff",borderRadius: "10px",margin:"5px"}} ></img> 
                                        </a>
                                      </div>
                                    </>
                                  )
                                }
                              }

                            })
                          }
                      </div>
                      </>
                    )
                  })
                }

              </div>
            </div>
          </div>
        */}
        {
        // <hr className="my-3 w-25" />
        // <div className="d-md-inline-flex icons-container">
        //   <a
        //     href="https://www.github.com"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <FontAwesomeIcon
        //       icon={["fab", "github"]}
        //       className="icons github"
        //       title="Github"
        //     />
        //   </a>
        //   <a
        //     href="https://linkedin.com"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <FontAwesomeIcon
        //       icon={["fab", "linkedin"]}
        //       className="icons linkedin"
        //       title="LinkedIn"
        //     />
        //   </a>
        //   <a
        //     href="https://www.freecodecamp.org"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <FontAwesomeIcon
        //       icon={["fab", "free-code-camp"]}
        //       className="icons fcc"
        //       title="FreeCodeCamp"
        //     />
        //   </a>
        //   <a
        //     href="https://www.hackerrank.com/"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <FontAwesomeIcon
        //       icon={["fab", "hackerrank"]}
        //       className="icons hr"
        //       title="Hackerrank"
        //     />
        //   </a>
        //   <a
        //     href="mailto:johndoe@gmail.com"
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <FontAwesomeIcon
        //       icon={["fas", "envelope"]}
        //       className="icons mail"
        //       title="e-mail"
        //     />
        //   </a>
        //   <a href="../../resume.pdf" target="_blank" download>
        //     <FontAwesomeIcon
        //       icon={["fas", "file-alt"]}
        //       className="icons file"
        //       title="Resume"
        //     />
        //   </a>
        // </div>
        }
    </PageLayout>
  )
}
